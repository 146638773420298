export default [
  { name: "Accommodation and food services" },
  { name: "Administrative and support services" },
  { name: "Agriculture, forestry and fishing" },
  { name: "Arts and recreation services" },
  { name: "Book" },
  { name: "Construction" },
  { name: "Education and training" },
  { name: "Electricity, gas, water and waste services" },
  { name: "Financial and insurance services" },
  { name: "Health care and social assistance" },
  { name: "Information media and telecommunications" },
  { name: "Manufacturing" },
  { name: "Mining" },
  { name: "Other services" },
  { name: "Professional, scientific and technical services" },
  { name: "Public administration and safety" },
  { name: "Rental, hiring and real estate services" },
  { name: "Retail trade" },
  { name: "Transport, postal and warehousing" },
  { name: "Wholesale trade" },
];
