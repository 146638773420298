import accomodation from "./categories/accomodation";
import administrative from "./categories/administrative";
import agriculture from "./categories/agriculture";
import arts from "./categories/arts";
import construction from "./categories/construction";
import education from "./categories/education";
import electricity from "./categories/electricity";
import finance from "./categories/finance";
import healthcare from "./categories/healthcare";
import informationmedia from "./categories/informationmedia";
import manufacturing from "./categories/manufacturing";
import minings from "./categories/minings";
import otherservices from "./categories/otherservices";
import professional from "./categories/professional";
import publicadmin from "./categories/publicadmin";
import rental from "./categories/rental";
import rentaltrade from "./categories/rentaltrade";
import transport from "./categories/transport";
import wholesaletrade from "./categories/wholesaletrade";

export default [
  {
    industry: "Book",
    "name": "Religious services"
  },
  ...accomodation,
  ...administrative,
  ...agriculture,
  ...arts,
  ...construction,
  ...education,
  ...electricity,
  ...finance,
  ...healthcare,
  ...informationmedia,
  ...manufacturing,
  ...minings,
  ...otherservices,
  ...professional,
  ...publicadmin,
  ...rental,
  ...rentaltrade,
  ...transport,
  ...wholesaletrade,
]
